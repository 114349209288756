document.addEventListener('DOMContentLoaded', function(){

	const anchorLinks = document.querySelectorAll('.js-anchor-link');
	anchorLinks.forEach(function(link) {
		link.addEventListener('click', function(e) {

			const targetName = link.getAttribute('href').slice(1),
						targetEl = document.getElementById(targetName);
			if (targetEl) {
				e.preventDefault();
				
				targetEl.scrollIntoView({
					behavior: 'smooth'
				});

				closeMenu();
			}
		});
	});

	const closePopup = popup => {
		const video = document.querySelector('.popup__video');

		if(video) {
			video.pause();
			video.currentTime = 0;
		}

		if (popup.hasAttribute('data-delete')){
			popup.remove();
		} else {
			popup.classList.remove('popup--active');
		}

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function(e){
		var tg = e.target;

		if (tg.classList.contains('popup')){
			closePopup(tg);
			return;
		}

		if (tg.classList.contains('popup__close-btn') || tg.hasAttribute('data-close-popup')){
			var popup = tg.closest('.popup');
			
			closePopup(popup);
		}
	});
	
	document.addEventListener('keyup', function(e){
		if (e.keyCode == 27) {
			const popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}
	});

	// open popups
	document.addEventListener('click', function(e){
		const target = e.target.closest('[data-popup-target]');
		
		if (target) {
			const popup = document.querySelector('.'+target.dataset.popupTarget);
			const oldPopup = document.querySelector('.popup--active');
			
			if(oldPopup) {
				popup.classList.remove('popup--active');
			}

			if (popup) {
				e.preventDefault();
				popup.classList.add('popup--active');

				const video = popup.querySelector('video');
				if (video) {
					video.play();
				}
			}

			if(target.hasAttribute('data-buy-id')) {
				popup.dataset.buy = target.dataset.buyId;
			}

			return false;
		}
	});


	// observe the scrolling blocks
	const faders = document.querySelectorAll('.fade-in');

	// observe the scrolling blocks
	const appearOptions = {
		threshold: 0,
		rootMargin: "0px 0px -250px 0px",
	};

	const appearOnScroll = new IntersectionObserver(function (
		entries,
		appearOnScroll
	) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				return;
			} else {
				entry.target.classList.add("appear");
				appearOnScroll.unobserve(entry.target);
			}
		});
	},
	appearOptions);

	faders.forEach((fader) => {
		appearOnScroll.observe(fader);
	});

});
